import styled from "styled-components";

export const RowFound = styled.div`
  font-size: 0.75rem;
`;

export const PaginationButton = styled.button`
  font-size: 0.75rem;
  border-radius: 5px;
  padding: 6px 8px 7px 8px;
  margin-right: 3px;
  margin-left: 3px;
`;

export const PaginationInfo = styled.span`
  display: flex;
  font-size: 0.85rem;
  align-items: center;
`;

export const SelectPaginationSize = styled.select`
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 0.75rem;
  border: 1px solid #ccc;
`;

export const GoToPage = styled.input`
  border-radius: 5px;
  padding: 5px 5px;
  font-size: 0.75rem;
  margin-left: 5px;
  width: 50px;
  border: 1px solid #ccc;
  text-align: center;
  justify-content: center;
  height: 25px;
`;
