import depradoTheme from "./deprado";
import defaultTheme from "./default";

export const getTheme = (themeName = "default") => {
  if (themeName === "deprado") {
    return depradoTheme;
  } else {
    return defaultTheme;
  }
};
