import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Span } from "./styled";
// import PATHS from "../../../../constants/paths";

const BreadCrumbs = ({ items, active }) => {
  //   const { t } = useTranslation();
  const navigate = useNavigate();

  const goTo = async (path) => {
    navigate(path);
  };

  return (
    <>
      {items && items.length > 0 ? (
        <div className="row justify-content-center">
          <div className="col-12 col-md-11">
            <nav className="customBreadCrumb" aria-label="breadcrumb">
              <ol className="breadcrumb">
                {items.map((bread, index) => {
                  return (
                    <li
                      className={`breadcrumb-item ${
                        !bread.path ? "active" : ""
                      }`}
                      key={`bread_${index}`}
                    >
                      {bread.path ? (
                        <Button onClick={() => goTo(bread.path)}>
                          {bread.label}
                        </Button>
                      ) : (
                        <Span>{bread.label}</Span>
                      )}
                    </li>
                  );
                })}
              </ol>
            </nav>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

BreadCrumbs.propTypes = {};

export default BreadCrumbs;
