import styled from "styled-components";

export const Button = styled.button`
  /* margin-right: 2px; */
  color: #f9ae17;
  font-size: 1rem;
  border: 0px;
  background: transparent;

  &:hover {
    color: #f9ae17;
    font-size: 1rem;
    text-decoration: underline;
  }
`;

export const Span = styled.span`
  /* margin-right: 2px; */
  color: #203b34;
  font-size: 1rem;
  border: 0px;
  background: transparent;
`;
