const colors = {
  primary: "#0adfe0",
  primaryLight: "#0dbebe",
  secondary: "#35363a",
  fourthy: "#E03A85",
  terciary: "#E03A85",
  terciaryLight: "#ff4297",
  white: "#ffffff",
  red: "#ff0000",
};

const theme = {
  platforms: {
    smartnet: colors.primary,
    fidelitybots: colors.primary,
  },
  colors: {
    primary: colors.primary,
    primaryLight: colors.primaryLight,
    darkPrimary: "#00736c",
    terciary: colors.terciary,
    fourthy: colors.fourthy,
    secondary: colors.secondary,
    white: "#ffffff",
    warrning: "#e3e600",
    yellow: "#e3e600",
    alert: "#721c24",
    negative: colors.red,
  },
  paginations: {
    buttons: {
      disabled: "#fff",
      color: "#fff",
      hover: "#c5ad00",
      align: "center",
    },
  },
  notifications: {
    personal: {
      color: colors.primary,
      colorHover: colors.white,
      backgroundColor: colors.white,
      backgroundColorHover: colors.primary,
      badge: colors.fourthy,
    },
    general: {
      colorHover: colors.white,
      color: colors.primary,
      backgroundColor: colors.white,
      backgroundColorHover: colors.primary,
      badge: colors.fourthy,
    },
  },
  sidebar: {
    colorLink: "#ffffff",
    colorLinkHover: "#00a79d",
    backgroundColor: "#00000081", //'#35363a',
  },
  body: {
    backgroundColor: "#fdfdfd",
  },
  header: {
    backgroundColor: "#00000081",
  },
  buttons: {
    default: {
      backgroundColor: "#fff",
      backgroundColorHover: "#fff",
      color: "#000",
      colorHover: "#000",
      borderColor: "#000",
      borderColorHover: "#000",
    },
    primary: {
      backgroundColor: colors.primary,
      backgroundColorHover: colors.primaryLight,
      color: "#000",
      colorHover: "#fff",
      borderColor: colors.primary,
      borderColorHover: colors.primaryLight,
    },
    secondary: {
      backgroundColor: "#35363a",
      backgroundColorHover: "#1f1f1f",
      color: "#fff",
      colorHover: "#fff",
      borderColor: "#35363a",
      borderColorHover: "#545454",
    },
    terciary: {
      backgroundColor: colors.terciary,
      backgroundColorHover: colors.terciaryLight,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.terciary,
      borderColorHover: colors.terciaryLight,
    },
    alert: {
      backgroundColor: "#d00000",
      backgroundColorHover: "#e80000",
      color: "#fff",
      colorHover: "#fff",
      borderColor: "#d00000",
      borderColorHover: "#e80000",
    },
    trasparent: {
      backgroundColor: "trasparent",
      backgroundColorHover: "trasparent",
      color: "#fff",
      colorHover: "#fff",
      borderColor: "trasparent",
      borderColorHover: "trasparent",
    },
    simplePrimary: {
      backgroundColor: "#fff",
      backgroundColorHover: "#fff",
      color: colors.primary,
      colorHover: colors.primaryLight,
      borderColor: "#000",
      borderColorHover: "#000",
    },
  },
  links: {
    settings: {
      active: colors.secondary,
      default: colors.primary,
      hover: "#1d95c5",
    },
    default: {
      color: "#000",
      colorHover: "#000",
    },
    secondary: {
      color: "#E03A85",
      colorHover: "#E03A85",
    },
    primary: {
      color: colors.primary,
      colorHover: colors.primaryLight,
    },
  },
  text: {
    align: "justify",
  },
  title: {
    h1: {
      size: "1.8rem",
      family: "Conthrax",
    },
    h2: {
      size: "1.6rem",
      family: "Conthrax",
    },
    h3: {
      size: "1.4rem",
      family: "Conthrax",
    },
    h4: {
      size: "1.2rem",
      family: "Conthrax",
    },
    h5: {
      size: "1rem",
      family: "Conthrax",
    },
  },
  fonts: {
    family: "Conthrax",
    size: {
      sm: "0.65rem",
      md: "0.65rem",
      lg: "1rem",
      xlg: "1.5rem",
      xxlg: "2rem",
    },
  },
  providers: {
    corp: "#231f20",
    guard: "#25aae1",
    intrepid: "#134363",
    corpexpert: "#231f20",
    bitgoliat: "#fe0fe0",
    kucoinbot: "#fe0fe0",
  },
};

export default theme;
