// AuthContainer.ts

import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { useNavigate } from "react-router";
import { useToken } from "../token/useToken";
// import * as UserServices from "../../../services/UserServices";

function useAuth() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  // const refreshToken = useCallback(refresh, []);
  const onTokenInvalid = () => setUser(null);

  const { clearToken, isAuthenticated } = useToken(
    onTokenInvalid
    // refreshToken
  );

  // useEffect(() => {
  //   // try to get new token on first render using refresh token
  //   refreshToken();
  // }, [refreshToken]);

  useEffect(() => {
    // add listener for login or logout from other tabs
    window.addEventListener("storage", async (event) => {
      if (event.key === "SSO_LOGOUT" && isAuthenticated()) {
        await clearToken(false);
      } else if (event.key === "SSO_LOGIN") {
        // refreshToken();
      }
    });

    window.authenticateCallback = async function () {
      // await refreshToken();
      navigate("/home");
    };
  }, [clearToken, isAuthenticated, navigate]);

  const logout = useCallback(() => {
    clearToken().finally(() => {
      navigate("/");
      // fire an event to logout from all tabs
      window.localStorage.setItem("SSO_LOGOUT", new Date().toISOString());
    });
  }, [navigate, clearToken]);

  // async function refresh() {
  //   try {
  //     const data = await UserServices.refreshToken();
  //     if (data && data.access_token) {
  //     } else {
  //       logout();
  //     }
  //   } catch (error) {
  //     logout();
  //   }
  // }

  return {
    user,
    setUser,
    logout,
  };
}

export const AuthContainer = createContainer(useAuth);
