import React from "react";
import { Modal } from "react-bootstrap";
import { connectModal } from "redux-modal";
import PropTypes from "prop-types";
import { Mosaic } from "react-loading-indicators";

const ModalForm = ({ show, handleHide, body, backdrop, className }) => {
  return (
    <Modal
      show={show}
      onHide={handleHide}
      centered
      backdrop={true}
      keyboard={false}
      className="modal show"
      // className={className}
      // bsPrefix="loadingModal"
      dialogClassName="modal-loading"
    >
      <Modal.Body>
        <Mosaic color="#d1d0d099" size="large" text="" textColor="" />
      </Modal.Body>
    </Modal>
  );
};

ModalForm.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
  body: PropTypes.any,
};

export default connectModal({ name: "LoadingModal" })(ModalForm);
