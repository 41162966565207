import styled, { css } from "styled-components";
import ReactCountryFlag from "react-country-flag";

export const LanguageContainer = styled.div`
  margin-right: 10px;
  display: flex;
  margin-top: 0px;
  outline: 0px 0px !important;
  color: #fff;
`;

export const Flag = styled(ReactCountryFlag)`
  margin-right: 10px;
  height: 20px !important;
  width: auto !important;
`;
