import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import PaginateList from "../../../utils/PaginateList";
import * as CaptioConceptsServices from "../../../../services/CaptioConceptsService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import {
  ButtonAction,
  ButtonClose,
  Icon,
  Option,
  ListOptions,
} from "./captioConceptsList.styled";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import {} from "../../../security/permissions/rolesPermission";
import {
  checkBootstrapSizeBreak,
  // SIZES,
} from "../../../../utils/device/checkDevice";
import moment from "moment-timezone";
import { centsToMoney } from "../../../../utils/formats/number";
import { CONCEPT_TYPE } from "../../../../constants/index";

const CaptioConceptsListContainer = ({ filters, sortBy }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);
  const context = useContext(AbilityContext);

  const columns = [
    {
      header: t("forms.captioConcepts.table.date"),
      accessorKey: "createdAt",
      cell: (info) =>
        info.getValue() ? moment(info.getValue()).format("DD-MM-yyyy") : "-",
    },
    {
      header: t("forms.captioConcepts.table.companyCode"),
      accessorKey: "companyCode",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.captioConcepts.table.employeeCode"),
      accessorKey: "employeeCode",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.captioConcepts.table.status"),
      accessorKey: "status",
      cell: (info) => "IMPORTADO",
    },
    {
      header: t("forms.captioConcepts.table.country"),
      accessorKey: "dataImported.messageData.countryCode",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.captioConcepts.table.amount"),
      accessorKey: "dataImported.messageData.amount",
      cell: (info) => `${centsToMoney(info.getValue())}€`,
    },
    {
      header: t("forms.captioConcepts.table.type"),
      accessorKey: "dataImported.messageData.conceptCode",
      cell: (info) => {
        const type = CONCEPT_TYPE.find((st) => st.code === info.getValue());
        return type ? t(type.label) : info.getValue();
      },
    },
    // {
    //   header: t("forms.captioConcepts.table.dataImported"),
    //   accessorKey: "dataImported",
    //   cell: (info) => info.getValue(),
    // },
    // {
    //   Header: t("forms..table.actions"),
    //   accessorKey: "uuid",
    //   cell: (info) => renderAction(info.row.original),
    // },
  ];

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-date";
    }
    const result = await CaptioConceptsServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  // if (deviceSize < SIZES.md) {
  //   // hideColumn.itemCode = false;
  //   resize = false;
  // } else if (deviceSize === SIZES.md) {
  //   // hideColumn.itemCode = false;
  // }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

CaptioConceptsListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
};

export default CaptioConceptsListContainer;
