import { store } from "../store/index";
import {
  filterCaptioConcepts,
  removeCaptioConcepts,
  modifyCaptioConcepts,
  getCaptioConceptsByUuid,
  createCaptioConcepts,
  listConceptsToExport,
} from "../store/actions/CaptioConceptsActions";

/**
 * Create CaptioConcepts
 */
export const addCaptioConcepts = async (values) => {
  const objSend = { ...values };
  const result = await store.dispatch(createCaptioConcepts(objSend));
  if (result?.payload?.data?.uuid) {
    return "OK";
  }
  return null;
};

/**
 * Get CaptioConcepts
 */
export const getCaptioConcepts = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(getCaptioConceptsByUuid(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update CaptioConcepts
 */
export const updateCaptioConcepts = async (values, uuid) => {
  const objSend = { ...values };
  const param = [uuid];
  const result = await store.dispatch(modifyCaptioConcepts(objSend, param));
  if (result?.payload?.data?.uuid) {
    return "OK";
  }
  return null;
};

/**
 * List CaptioConcepts
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterCaptioConcepts(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Delete CaptioConcepts
 */
export const deleteCaptioConcepts = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(removeCaptioConcepts(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List order
 */
export const listToExport = async (filter) => {
  const result = await store.dispatch(listConceptsToExport(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
