/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import TextErrorMessage from "../TextErrorMessage";
// import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class InputDate extends Component {
  constructor(props) {
    super(props);
    const getLocale = (locale) =>
      require(`date-fns/locale/${this.props.language || "en-GB"}/index.js`);
    this.locale = getLocale(this.props.language);
    this.state = {
      defaultValue:
        this.props.field &&
        this.props.field.value !== undefined &&
        this.props.field.value !== null
          ? this.props.field.value
          : "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.field.value !== this.props.field.value) {
      this.setValue(this.props.field.value);
    }
    if (prevProps.language !== this.props.language) {
      this.selectLanguage();
    }
  }

  selectLanguage() {
    const getLocale = (locale) =>
      require(`date-fns/locale/${this.props.language || "en-GB"}/index.js`);
    this.locale = getLocale(this.props.language);
  }

  setValue(newValue) {
    const {
      form,
      field: { name },
    } = this.props;
    form.setFieldValue(name, newValue);
    this.setState({ defaultValue: newValue ? new Date(newValue) : null });
  }

  isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  async handleChange(date) {
    const {
      form,
      field: { name },
      onChangeValue,
      defaultHours,
      defaultMinutes,
    } = this.props;
    if (date) {
      if (
        defaultHours &&
        defaultMinutes &&
        date.getHours() === 0 &&
        date.getMinutes() === 0
      ) {
        date.setHours(defaultHours, defaultMinutes);
      }
    }
    if (onChangeValue) {
      onChangeValue(date);
    }
    form.setFieldValue(name, date);
    this.setState({ defaultValue: date });
  }

  render() {
    const {
      field: { name },
      labelField,
      placeholder,
      id,
      divClassName,
      mandatory,
      visible,
      tooltip,
      typeStyleErrorMessage,
      readOnly,
      dateFormat,
      className,
      timeFormat,
      showTimeSelect,
      timeIntervals,
      showTimeSelectOnly,
      showMonthYearPicker,
      showFullMonthYearPicker,
      showFourColumnMonthYearPicker,
      showTwoColumnMonthYearPicker,
      classNameContainer,
    } = this.props;

    return (
      <div
        id={`${id}_Container`}
        className={`${
          visible !== undefined && visible === false ? "field-hidden" : ""
        } form-group ${classNameContainer || ""}`}
      >
        {labelField && (
          <div
            className={`labelDiv ${divClassName}`}
            title={tooltip || labelField}
          >
            <label
              id={`${id}_labelField`}
              className={
                mandatory ? "control-label mandatory" : "control-label"
              }
              htmlFor={id}
            >
              {`${labelField}${mandatory ? " *" : ""}`}
            </label>
          </div>
        )}

        <div className={`${divClassName}`}>
          <DatePicker
            id={id}
            name={name}
            className={`inputFormDate ${className}`}
            placeholderText={placeholder}
            disabled={readOnly || false}
            selected={
              this.isValidDate(this.state.defaultValue)
                ? this.state.defaultValue
                : null
            }
            locale={this.locale}
            timeIntervals={timeIntervals || 15}
            timeFormat={timeFormat}
            showMonthYearPicker={showMonthYearPicker || false}
            showTimeSelectOnly={showTimeSelectOnly || false}
            showFullMonthYearPicker={showFullMonthYearPicker || false}
            showFourColumnMonthYearPicker={
              showFourColumnMonthYearPicker || false
            }
            showTwoColumnMonthYearPicker={showTwoColumnMonthYearPicker || false}
            showTimeSelect={showTimeSelect || false}
            dateFormat={dateFormat || "dd/MM/yyyy"}
            onChange={async (date) => {
              this.handleChange(date);
            }}
          />
        </div>
        <ErrorMessage
          name={name}
          component={TextErrorMessage}
          type={typeStyleErrorMessage || "default"}
        />
      </div>
    );
  }
}

InputDate.propTypes = {
  field: PropTypes.any,
  id: PropTypes.string,
  labelField: PropTypes.string,
  divClassName: PropTypes.string,
  visible: PropTypes.bool,
  mandatory: PropTypes.bool,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  readOnly: PropTypes.bool,
  onChangeValue: PropTypes.func,
  dateFormat: PropTypes.string,
  className: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  typeStyleErrorMessage: PropTypes.string,
  timeFormat: PropTypes.string,
  timeIntervals: PropTypes.number,
  showTimeSelectOnly: PropTypes.bool,
  language: PropTypes.string,
  defaultHours: PropTypes.number,
  defaultMinutes: PropTypes.number,
  showMonthYearPicker: PropTypes.bool,
  showFullMonthYearPicker: PropTypes.bool,
  showFourColumnMonthYearPicker: PropTypes.bool,
  showTwoColumnMonthYearPicker: PropTypes.bool,
  classNameContainer: PropTypes.string,
};

export default InputDate;
