import React from "react";
import { useTranslation } from "react-i18next";
import { FieldErrorContainer } from "./TextErrorMessage.styled";

const FieldError = (props) => {
  const { t } = useTranslation();

  let args = null;
  let message = null;
  if (typeof props.children === "object") {
    message = props.children.message;
    if (props.children.args) {
      args = props.children.args;
    }
  } else {
    message = props.children;
  }
  return (
    <FieldErrorContainer
      className={`error ${props.type ? props.type : "default"}`}
      type={props.type || "default"}
    >
      {args ? t(message, args) : t(message)}
    </FieldErrorContainer>
  );
};

export default FieldError;
