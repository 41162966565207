import React, { useState } from "react";
import Header from "../commons/header";
import Sidebar from "../commons/sidebar";
import {
  checkBootstrapTypeBreak,
  checkBootstrapSizeBreak,
} from "../../../utils/device/checkDevice";
import { OffCanvas, SideBarContainer, BodyContainer } from "./styled";
// import * as NotificationService from "../../../services/NotificationService";
// import {
//   getNicknameSelector,
//   getUserRoleSelector,
// } from "../../../store/selectors/authSelector";
// import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const VerticalLayout = ({ ...prop }) => {
  const breakDevice = checkBootstrapTypeBreak();
  const size = checkBootstrapSizeBreak();
  // const role = useSelector(getUserRoleSelector);
  // const nickName = useSelector(getNicknameSelector);
  const { t } = useTranslation();
  // NotificationService.getPendingNotification();
  const [toggle, setToggle] = useState(
    breakDevice === "xs" || breakDevice === "sm" || breakDevice === "md"
      ? false
      : true
  );
  const hideShowSidebar = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <OffCanvas
        className="offcanvas offcanvas-start d-flex justify-content-between flex-wrap flex-column"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <Sidebar
          offcanvas
          // userStatus={status}
          // nickname={nickName}
          // role={role}
        />
      </OffCanvas>

      <SideBarContainer
        className={`side-navbar ${
          toggle ? "active-nav" : ""
        }  d-flex justify-content-between flex-wrap flex-column`}
        id="sidebar"
      >
        {/* <Sidebar userStatus={status} nickname={nickName} role={role} /> */}
        <Sidebar />
      </SideBarContainer>

      <div className={`my-container  ${toggle ? "active-cont" : ""}`}>
        <BodyContainer
          $deviceSize={size}
          id="page-wrapper"
          className="container-fluid"
        >
          <Header
            withSidebar={true}
            toggleFunc={() => {
              hideShowSidebar();
            }}
          />
          <div id="page-content-wrapper">
            <div id="page-content">{prop.body}</div>
          </div>
        </BodyContainer>
      </div>
    </>
  );
};

export default VerticalLayout;
