import { handleActions } from "redux-actions";

import {
  CLEAN_AUTH,
  SET_AUTH_TOKEN,
  SET_AUTH_USER,
} from "../../constants/index";

export const auth = handleActions(
  {
    [CLEAN_AUTH]: () => ({}),
    [SET_AUTH_USER]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [SET_AUTH_TOKEN]: (state, action) => ({
      ...state,
      dataToken: action.payload,
    }),
  },
  {}
);
