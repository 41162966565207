import styled from "styled-components";

export const ContainerIcon = styled.div`
  color: #fff;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonIcon = styled.button`
  color: ${({ theme }) => theme.notifications.personal.color} !important;
  background-color: ${({ theme }) =>
    theme.notifications.personal.backgroundColor};
  border: 0px;
  border-radius: 30px;
  height: 35px;
  width: 35px;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.notifications.personal.colorHover} !important;
    background-color: ${({ theme }) =>
      theme.notifications.personal.backgroundColorHover} !important;
  }

  &:active {
    color: ${({ theme }) => theme.notifications.personal.colorHover} !important;
    background-color: ${({ theme }) =>
      theme.notifications.personal.backgroundColorHover} !important;
  }
`;

export const Badge = styled.span`
  color: #fff;
  top: 6px !important;
  padding: 0.2rem 0.4rem !important;
  font-size: 0.65rem !important;
  background-color: ${({ theme }) =>
    theme.notifications.personal.badge} !important;
`;
