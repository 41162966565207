/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { ErrorMessage } from "formik";
import TextErrorMessage from "../TextErrorMessage";
// import "bootstrap/dist/css/bootstrap.min.css";

class InputSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      options: [],
    };
  }

  componentDidMount() {
    this.parseOptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.parseOptions();
    }
    if (prevProps.field.value !== this.props.field.value) {
      this.setValue(this.props.field.value);
    }
  }

  setValue(newValue) {
    const {
      form,
      isMultiple,
      field: { name },
    } = this.props;

    if (isMultiple) {
      const newOptionsSelected = [];
      for (const op of newValue) {
        const option = this.state.options.find(
          (element) => element.value === op
        );
        if (option) {
          newOptionsSelected.push(option);
        }
        form.setFieldValue(name, newValue);
        this.setState({ selectedOption: newOptionsSelected });
      }
    } else {
      const newSelection = this.state.options.find(
        (elememnt) => elememnt.value === newValue
      );
      if (newSelection) {
        form.setFieldValue(name, newSelection.value);
        this.setState({ selectedOption: newSelection });
      }
    }
  }

  async parseOptions() {
    const {
      options,
      optionLabel,
      optionValue,
      multilanguage,
      translateFunction,
      addOptionEmpty,
      loadWith,
    } = this.props;
    if (loadWith) {
      let newOptions = [];
      const ops = await loadWith();
      if (ops && ops.length > 0) {
        newOptions = ops.map((op) => {
          let dat = null;
          if (op.remoteVisibleField) {
            const entries = Object.entries(op.remoteVisibleField);
            let value;
            let label = "";
            for (const entry of entries) {
              if (entry[0] === "uuid") {
                value = entry[1];
              } else {
                label += `${entry[1]} `;
              }
            }
            dat = { value, label };
          } else {
            dat = { value: op[optionValue], label: op[optionLabel] };
          }
          if (
            this.props.field &&
            this.props.field.value &&
            this.props.field.value === op[optionValue]
          ) {
            this.setState({ selectedOption: dat });
          }
          return dat;
        });
      }
      if (addOptionEmpty) {
        const opEmpty = {
          value: "",
          label: "",
        };
        newOptions.push(opEmpty);
      }
      this.setState({ options: newOptions });
    } else {
      if (options && options.length > 0) {
        const newOptions = options.map((op) => {
          let dat = null;
          if (multilanguage) {
            dat = {
              value: op[optionValue],
              label: translateFunction(op[optionLabel]),
            };
          } else {
            dat = { value: op[optionValue], label: op[optionLabel] };
          }
          if (
            this.props.field &&
            this.props.field.value &&
            this.props.field.value === op[optionValue]
          ) {
            this.setState({ selectedOption: dat });
          }
          return dat;
        });

        if (addOptionEmpty) {
          const opEmpty = {
            value: "",
            label: "",
          };
          newOptions.push(opEmpty);
        }
        this.setState({ options: newOptions });
      } else {
        this.setState({ options: [], selectedOption: null });
      }
    }
  }

  async handleChange(selectedOption, event) {
    const {
      form,
      field: { name },
      onChangeValue,
      isMultiple,
      whenChangeSumbit,
    } = this.props;

    let resultMulti;
    if (selectedOption) {
      if (isMultiple) {
        resultMulti = selectedOption.map((option) => option.value);
      }
      if (onChangeValue) {
        onChangeValue(isMultiple ? resultMulti : selectedOption.value);
      }
      form.setFieldValue(
        name,
        isMultiple ? resultMulti : selectedOption.value,
        true
      );
      this.setState({ selectedOption });
      if (whenChangeSumbit) {
        form.submitForm();
      }
    } else {
      form.setFieldValue(name, "", true);
      this.setState({ selectedOption });
    }
  }

  render() {
    const {
      field: { name },
      labelField,
      placeholder,
      id,
      divClassName,
      mandatory,
      visible,
      tooltip,
      typeStyleErrorMessage,
      readOnly,
      classNamePrefix,
      className,
      classNameContainer,
      isMultiple,
    } = this.props;

    return (
      <div
        id={`${id}_Container`}
        className={`${
          visible !== undefined && visible === false ? "field-hidden" : ""
        } form-group ${classNameContainer || ""}`}
      >
        {labelField && (
          <div
            className={`labelDiv ${divClassName}`}
            title={tooltip || labelField}
          >
            <label
              id={`${id}_labelField`}
              className={
                mandatory ? "control-label mandatory" : "control-label"
              }
              htmlFor={id}
            >
              {`${labelField}${mandatory ? " *" : ""}`}
            </label>
          </div>
        )}

        <div className={`${divClassName}`}>
          <Select
            name={name}
            value={this.state.selectedOption}
            onChange={(val, event) => this.handleChange(val, event)}
            options={this.state.options}
            placeholder={placeholder}
            isDisabled={readOnly}
            classNamePrefix={classNamePrefix}
            className={className}
            isMulti={isMultiple || false}
          />
        </div>
        <ErrorMessage
          name={name}
          component={TextErrorMessage}
          type={typeStyleErrorMessage || "default"}
        />
      </div>
    );
  }
}

InputSelect.propTypes = {
  field: PropTypes.any,
  id: PropTypes.string,
  labelField: PropTypes.string,
  divClassName: PropTypes.string,
  visible: PropTypes.bool,
  mandatory: PropTypes.bool,
  options: PropTypes.any,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  multilanguage: PropTypes.bool,
  translateFunction: PropTypes.func,
  readOnly: PropTypes.bool,
  onChangeValue: PropTypes.func,
  classNamePrefix: PropTypes.string,
  className: PropTypes.string,
  addOptionEmpty: PropTypes.bool,
  loadWith: PropTypes.func,
  whenChangeSumbit: PropTypes.bool,
  classNameContainer: PropTypes.string,
  isMultiple: PropTypes.bool,
};

export default InputSelect;
