import React from "react";
import FilterList from "../../../components/user/captioConcepts/captioConceptsFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const CaptioConceptsListPage = (props) => {
  return <Layout body={<FilterList />} />;
};

CaptioConceptsListPage.propTypes = {};

export default CaptioConceptsListPage;
