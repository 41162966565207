import React, { useState } from "react";
import List from "../captioConceptsList";
import Filter from "../captioConceptsFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import * as XLSX from "xlsx";
import * as CaptioConceptsServices from "../../../../services/CaptioConceptsService";
import { CONCEPT_TYPE } from "../../../../constants/index";
import moment from "moment-timezone";
import { centsToMoney } from "../../../../utils/formats/number";

const queryClient = new QueryClient();

const CaptioConceptsFilterListContainer = (props) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(undefined);
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.captioConcepts.title") },
  ];

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const exportToExcel = async () => {
    const filter = {
      filters,
      sort: "-createdAt",
    };
    CaptioConceptsServices.listToExport(filter).then((result) => {
      const data = result.map((concept) => {
        let type;
        if (concept.dataImported.messageData.conceptCode !== undefined) {
          type = CONCEPT_TYPE.find(
            (st) => st.code === concept.dataImported.messageData.conceptCode
          );
        }
        return {
          A: new Date(concept.createdAt),
          B: concept.companyCode || "",
          C: concept.employeeCode,
          D: "IMPORTADO",
          E:
            concept.dataImported.messageData.countryCode === "ES"
              ? "ESPAÑA"
              : "PORTUGAL",
          F: `${centsToMoney(concept.dataImported.messageData.amount)}€`,
          G: type
            ? t(type.label)
            : concept.dataImported.messageData.conceptCode,
        };
      });
      data.unshift({
        A: "Fecha",
        B: "Código Empresa",
        C: "Código Empleado",
        D: "Estado",
        E: "País",
        F: "Cantidad",
        G: "Tipo",
      });

      const filename = `Gastos_export_${moment(new Date()).format(
        "DDMMyyyyHHmm"
      )}`;
      const sheetName = "Listado de gastos";
      var ws = XLSX.utils.json_to_sheet(data, {
        header: ["A", "B", "C", "D", "E", "F", "G"],
        skipHeader: true,
        UTC: true,
        dateNF: "dd-mm-yyyy hh:mm;@",
      });
      // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.captioConcepts.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div>
      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-11 text-end">
          <button
            type="button"
            className="btn btn-md btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List
              className="marg-top-1rem"
              filters={filters}
              sortBy={"-createdAt"}
            />
          </QueryClientProvider>
        </div>
      </div>
    </>
  );
};

CaptioConceptsFilterListContainer.propTypes = {};

export default CaptioConceptsFilterListContainer;
