/* eslint-disable import/no-cycle */
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import promiseMiddleware from "redux-promise";
import ReduxThunk from "redux-thunk";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localForage from "localforage";
import reducers from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage: localForage,
  blacklist: ["modal"],
  stateReconciler: autoMergeLevel2, // see 'Merge Process' section for details.
};

const persistReducers = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistReducers,
  {},
  composeEnhancers(applyMiddleware(promiseMiddleware, ReduxThunk))
);

export const persistor = persistStore(store);
