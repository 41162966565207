import { axios } from "../../../api/axios";
import { useCallback, useEffect, useRef } from "react";
// import { configure } from "axios-hooks";
import { useTokenExpiration } from "./useTokenExpiration";
import * as UserServices from "../../../services/UserServices";
import { handleErrorResponse } from "../../../utils/errors/ErrorControl";
import { useSelector } from "react-redux";
import { getDataTokenSelector } from "../../../store/selectors/authSelector";

export const useToken = (onTokenInvalid) => {
  const accessToken = useRef();
  const dataToken = useSelector(getDataTokenSelector);
  const { clearAutomaticTokenRefresh, setTokenExpiration } =
    useTokenExpiration();

  useEffect(() => {
    if (dataToken && dataToken.access_token) {
      accessToken.current = dataToken.access_token;
      const expirationDate = new Date(dataToken.token_expiration);
      setTokenExpiration(expirationDate);
    }
  }, [dataToken, setTokenExpiration]);

  const isAuthenticated = useCallback(() => {
    return !!accessToken.current;
  }, []);

  const clearToken = useCallback(
    (shouldClearCookie = true) => {
      // if we came from a different tab, we should not clear the cookie again
      const clearRefreshTokenCookie = shouldClearCookie
        ? UserServices.logout()
        : Promise.resolve();

      // clear refresh token
      return clearRefreshTokenCookie.finally(() => {
        // clear token
        accessToken.current = "";

        // clear auto refresh interval
        clearAutomaticTokenRefresh();
      });
    },
    [clearAutomaticTokenRefresh]
  );

  useEffect(() => {
    // if the current token is expired or invalid, logout the user
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401 && accessToken.current) {
          clearToken();
          // let the app know that the current token was cleared
          onTokenInvalid();
        }
        handleErrorResponse(error);
        return Promise.reject(error);
      }
    );

    // configure axios-hooks to use this instance of axios
    // configure({ axios });
  }, [clearToken, onTokenInvalid]);

  return {
    clearToken,
    isAuthenticated,
  };
};
