import React from "react";
import {
  RowFound,
  PaginationButton,
  PaginationInfo,
  GoToPage,
  SelectPaginationSize,
} from "./PaginateList.styled";
import { useTranslation } from "react-i18next";

const DefaultPagination = ({ table }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-12 d-flex flex-column flex-md-row justify-content-center align-items-center">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <PaginationInfo className="ms-3">
              <div className="me-1">{`${t("pagination.page")} `}</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} {t("pagination.de")}{" "}
                {table.getPageCount()}
              </strong>
            </PaginationInfo>
            <PaginationInfo className="ms-2 me-3">
              | {t("pagination.goTo")}:
              <GoToPage
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
              />
            </PaginationInfo>
          </div>
          <div className="mt-2 mt-md-0">
            <select
              // className="pagination-select"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {t("pagination.show", { pageSize })}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
          <PaginationButton
            className="btn btn-primary"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}{" "}
            <span className="d-none d-md-inline">{t("pagination.first")}</span>
          </PaginationButton>
          <PaginationButton
            className="btn btn-primary"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}{" "}
            <span className="d-none d-md-inline">{t("pagination.prev")}</span>
          </PaginationButton>
          <PaginationButton
            className="btn btn-primary"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="d-none d-md-inline">{t("pagination.next")}</span>{" "}
            {">"}
          </PaginationButton>
          <PaginationButton
            className="btn btn-primary"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="d-none d-md-inline">{t("pagination.last")}</span>{" "}
            {">>"}
          </PaginationButton>
        </div>
      </div>
      <div className="row justify-content-center mt-2">
        <div className="col-12 col-md-12 text-center">
          <RowFound>
            {table.getState().totalDocs} {t("pagination.result")}.
          </RowFound>
        </div>
      </div>
    </>
  );
};

export default DefaultPagination;
