import { createAction } from "redux-actions";
import { apiGet, apiPost, apiPut, apiDelete } from "../../api/index";

import {
  urlCaptioConceptsSecured,
  urlExportConceptsSecured,
} from "../../api/urls";

import { PAGE_SIZE_10 } from "../../constants/index";
import moment from "moment-timezone";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      filter += "&dateInit=" + dateInit.valueOf();
    }
    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      filter += "&dateEnd=" + dateEnd.valueOf();
    }
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list CaptioConcepts.
 */
export const filterCaptioConcepts = createAction(
  "FILTER_CAPTIO_CONCEPTS",
  (filter, param) =>
    apiGet(
      urlCaptioConceptsSecured + generateFilterQuery(filter),
      param,
      true
    )()
);

/**
 * Get CaptioConcepts by uuid.
 */
export const getCaptioConceptsByUuid = createAction(
  "GET_CAPTIO_CONCEPTS",
  (param) => apiGet(urlCaptioConceptsSecured, param, true)()
);

/**
 * Create CaptioConcepts.
 */
export const createCaptioConcepts = createAction(
  "CREATE_CAPTIO_CONCEPTS",
  (obj) => apiPost(urlCaptioConceptsSecured, obj, undefined, true)()
);

/**
 * Update CaptioConcepts.
 */
export const modifyCaptioConcepts = createAction(
  "UPDATE_CAPTIO_CONCEPTS",
  (obj, param) => apiPut(urlCaptioConceptsSecured, obj, param, true)()
);

/**
 * Delete CaptioConcepts by uuid.
 */
export const removeCaptioConcepts = createAction(
  "DELETE_CAPTIO_CONCEPTS",
  (param) => apiDelete(urlCaptioConceptsSecured, param, true)()
);

/**
 * Filter list orders.
 */
export const listConceptsToExport = createAction(
  "EXPORT_FILTER_CONCEPTS",
  (filter, param) =>
    apiGet(
      urlExportConceptsSecured + generateFilterQuery(filter),
      param,
      true
    )()
);
