import styled from "styled-components";

export const Container = styled.div`
  min-height: calc(100vh - 120px);
  background: transparent;
`;

export const Title = styled.span`
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
`;
