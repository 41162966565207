import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContainer } from "./components/security/auth/auth.container";
import "./utils/i18n/i18n";
import { ThemeProvider } from "styled-components";
import { getTheme } from "./design/themes/utilTheme";
import { AbilityContext } from "./components/security/permissions/Can";
import { buildAbilityFor } from "./components/security/permissions/ability";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const ability = buildAbilityFor();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <AuthContainer.Provider>
          <ThemeProvider theme={getTheme(process.env.REACT_APP_THEME)}>
            <AbilityContext.Provider value={ability}>
              <App />
            </AbilityContext.Provider>
          </ThemeProvider>
        </AuthContainer.Provider>
      </Router>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorkerRegistration.unregister();

serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // Corremos este código si hay una nueva versión de nuestra app
    // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
      window.location.reload();
    }
  },
});
