import { Navigate, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
// import NewEmployeePage from "./pages/newEmployee";
import AuthConfirmPage from "./pages/authConfirm";
import LoginPage from "./pages/login";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PATHS from "./constants/paths";
import AccountLinkListPage from "./pages/accountLinks/list";
import AccountLinkResumePage from "./pages/accountLinks/resume";
import AccountLinkGeneratePage from "./pages/accountLinks/generate";
import AccountLinkDetailsPage from "./pages/accountLinks/details";
import CaptioConceptsListPage from "./pages/captioConcepts/list";

function App() {
  // const navigate = useNavigate();
  // const { user } = AuthContainer.useContainer();

  return (
    <Routes>
      {/* <Route path="/logout" element={<Logout />} /> */}
      <Route path="/user" element={<ProtectedRoutes />}>
        <Route path={PATHS.HOME} exact element={<HomePage />} />
        <Route
          path={PATHS.CAPTIO_CONCEPTS_LIST}
          exact
          element={<CaptioConceptsListPage />}
        />
        <Route
          path={PATHS.ACCOUNT_LINKS}
          exact
          element={<AccountLinkListPage />}
        />
        <Route
          path={PATHS.GENERATE_ACCOUNT_LINKS}
          exact
          element={<AccountLinkGeneratePage />}
        />
        <Route
          path={PATHS.VIEW_RESUME_ACCOUNT_LINKS}
          exact
          element={<AccountLinkResumePage />}
        />
        <Route
          path={PATHS.DETAILS_ACCOUNT_LINKS}
          exact
          element={<AccountLinkDetailsPage />}
        />
        {/* <Route path={PATHS.NEW_EMPLOYEE} exact element={<NewEmployeePage />} /> */}
      </Route>

      <Route
        path={PATHS.EXTERNAL_CONFIRM_LOGIN}
        exact
        element={<AuthConfirmPage />}
      />
      <Route path="/login" exact element={<LoginPage />} />
      <Route path="/" element={<Navigate to={"/login"} />} />
    </Routes>
  );
}

export default App;
