import React, { useMemo, useState } from "react";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import PaginateList from "./PaginateList.view";
import { useQuery } from "@tanstack/react-query";
// import { withTranslation } from "react-i18next";

const PaginateListContainer = ({
  columns,
  toList,
  hideColum,
  filters,
  resize = true,
  refresh,
  resetRefresh,
}) => {
  // const columnsDef = useMemo(() => columns, []);
  const defaultData = useMemo(() => [], []);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const fetchData = async (pageIndex, pageSize) => {
    const filter = {
      page: pageIndex + 1,
      pageSize: pageSize || 10,
    };
    if (filters) {
      filter.filters = filters;
    }
    const result = await toList(filter);
    if (refresh) {
      resetRefresh();
    }
    return {
      rows: result?.docs || [],
      pageCount: result?.totalPages || 0,
      totalDocs: result?.totalDocs || 0,
    };
  };

  //TODO: force refresh
  const dataQuery = useQuery(
    {
      queryKey: [
        "data",
        {
          pageIndex,
          pageSize,
          filters,
          refresh,
        },
      ],
      queryFn: () => fetchData(pageIndex, pageSize),
      // queryKeyHashFn: (queryKey, dd) => refreshQuery(queryKey, dd),
      refetchOnWindowFocus: false,
    }
    // { keepPreviousData: true }
  );

  const table = useReactTable({
    data: dataQuery.data?.rows ?? defaultData,
    columns: columns, //columnsDef,
    enableColumnResizing: resize,
    columnResizeMode: "onChange",
    pageCount: dataQuery.data?.pageCount ?? -1,
    state: {
      columnVisibility: hideColum || {},
      pagination,
      totalDocs: dataQuery.data?.totalDocs ?? 0,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    // debugTable: true,
  });

  return <>{dataQuery.data ? <PaginateList table={table} /> : ""}</>;
  // return <PaginateList table={table} />;
};

export default PaginateListContainer;
